/* eslint-disable */

const getNavbarItems = (teamName) => [
  {
    key: 'report',
    title: 'ข้อมูลรายสถานี',
    titleHTML: `<i class="fas fa-chart-bar icon"></i> ข้อมูลรายสถานี`,
    link: `/${teamName}/report`,
  },
  {
    key: 'compare',
    title: 'เปรียบเทียบระหว่างสถานี',
    titleHTML: '<i class="fas fa-chart-bar icon"></i> เปรียบเทียบระหว่างสถานี',
    link: `/${teamName}/compare`,
  },
  {
    key: 'prime-time',
    title: 'เปรียบเทียบช่วง Prime Time',
    titleHTML: '<i class="fas fa-chart-bar icon"></i> เปรียบเทียบช่วง Prime Time',
    link: `/${teamName}/prime-time`,
  },
];

export {
  getNavbarItems,
};
