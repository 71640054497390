import _ from 'lodash';

const colors = [
  '#FFB900',
  '#D83B01',
  '#B50E0E',
  '#E81123',
  '#B4009E',
  '#5C2D91',
  '#0078D7',
  '#00B4FF',
  '#008272',
  '#107C10',
];

const issuesColor = {
  playerSetupError: {
    mainColor: '#E76F51',
    colors: [
      '#FFD3BB',
      '#F4A381',
      '#E76F51',
      '#AF4B37',
      '#7A2A1E',
      '#480A00',
    ],
  },
  playerError: {
    mainColor: '#E8B948',
    colors: [
      '#FFF2AF',
      '#F2D67C',
      '#E8B948',
      '#AA8130',
      '#6D4E1A',
      '#352100',
    ],
  },
  playbackError: {
    mainColor: '#2A9D8F',
    colors: [
      '#DFF5F1',
      '#A9D7CF',
      '#70BAAF',
      '#2A9D8F',
      '#1B685E',
      '#0C3731',
    ],
  },
  otherError: {
    mainColor: '#343967',
    colors: [
      '#E0EBFF',
      '#B0BCD8',
      '#838EB2',
      '#5A628C',
      '#343967',
      '#192043',
    ],
  },
  unidentified: {
    mainColor: '#9C9C9C',
    colors: [
      '#E4E4E4',
      '#EDEDED',
      '#9C9C9C',
      '#696969',
      '#323232',
      '#0A0A0A',
    ],
  },
};

const colorGeneratorFromString = (textString) => {
  const sum = _.sumBy(textString, item => item.charCodeAt(0));
  return colors[sum % colors.length];
};

const getIssuesColor = (name) => {
  if (!name) return issuesColor.unidentified;
  const nameCamelCase = name.replace(/([-_]\w)/g, g => g[1].toUpperCase());
  return issuesColor[nameCamelCase];
};

export {
  colorGeneratorFromString,
  getIssuesColor,
};
