<template>
  <div class="sidebar"
    v-if="isMobileMenuShowing || window.width > 991.98"
    v-click-outside="onClose">
    <slot
      name="sidebar-brand"
      v-if="$slots['sidebar-brand']" />
    <slot
      name="sidebar-project"
      v-if="$slots['sidebar-project']" />
    <slot></slot>
    <slot
      name="sidebar-user"
      v-if="$slots['sidebar-user']" />
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  props: {
    isMobileMenuShowing: {
      type: Boolean,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/sidebar.scss';
</style>
