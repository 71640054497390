<template>
  <div class="app">
    <NavbarContainer
      :onToggleMenu="onToggleMenu"
      :stateSidebar="state" />
    <SidebarAppContainer
      :onToggleMenu="onToggleMenu"
      :stateSidebar="state" />
    <router-view />
  </div>
</template>

<script>
import { loadStations } from '@/report/api';

import NavbarContainer from '@/components/NavbarContainer.vue';
import SidebarAppContainer from '@/components/SidebarAppContainer.vue';

export default {
  components: {
    NavbarContainer,
    SidebarAppContainer,
  },
  data() {
    return {
      state: {
        isMobileMenuShowing: false,
      },
    };
  },
  watch: {
    $route() {
      this.state.isMobileMenuShowing = false;
    },
  },
  created() {
    this.getStationList();
  },
  methods: {
    onToggleMenu(stateOfMobileShowing) {
      this.state.isMobileMenuShowing = stateOfMobileShowing;
    },
    async getStationList() {
      try {
        const response = await loadStations();
        const stationList = response.data;
        this.$store.commit('setStationList', stationList);
      } catch (error) {
        this.$store.commit('setStationList', null);
        this.$store.commit('setStationError', error);
      }
    },
  },
};
</script>

<style lang="scss">
@import './src/assets/scss/app.scss';
@import './src/assets/scss/variables.scss';

</style>
