import axiosAPI from '@/modules/axios';
import store from '@/store';

const baseURL = process.env.VUE_APP_ORIGIN || window.location.origin;

function configAuthAxios(teamName) {
  // setup axios for app
  axiosAPI.defaults.withCredentials = true;
  axiosAPI.defaults.headers.common = { 'X-ByteArk-Team': teamName };

  axiosAPI.defaults.baseURL = `${baseURL}/api/v1`;

  // Add a response interceptor
  axiosAPI.interceptors.response.use(response => response, async (error) => {
    if (error.response.status === 401) {
      await store.dispatch('destroyCurrentUser');
      window.location.href = `${baseURL}/accounts/byteark`;
    } else if (error.response.status === 403) {
      await store.dispatch('destroyCurrentUser');
      window.location.href = `${baseURL}/permissiondenied`;
    }
    return Promise.reject(error);
  });
}

export default configAuthAxios;
