/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import _ from 'lodash';

const state = {
  isLoading: false,
  currentUser: null,
  userCurrentStation: [],
  stationList: [],
  stationError: null,
};

const getDefaultState = () => ({
  isLoading: false,
  currentUser: null,
  userCurrentStation: [],
  stationList: [],
  stationError: null,
});

const getters = {
  isLoading: state => state.isLoading,
  currentUser: state => state.currentUser,
  userName: state => _.get(state.currentUser, 'name', ''),
  userTeam: state => _.get(state.currentUser, 'team.name', null),
  userEmail: state => _.get(state.currentUser, 'email', null),
  userStationName: state => _.get(state.userCurrentStation, '0.stationName', null),
};

const mutations = {
  setIsCurrentUserLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setCurrentUser(state, currentUser) {
    state.currentUser = _.cloneDeep(currentUser);
  },
  destroyCurrentUser(state) {
    state.currentUser = null;
  },
  setUserStation(state, station) {
    state.userCurrentStation = station;
  },
  resetUserStation(state) {
    state.userCurrentStation = [];
  },
  setStationList(state, stationList) {
    state.stationList = stationList;
  },
  setStationError(state, error) {
    state.stationError = error;
  },
  resetUserState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  setCurrentUser({ commit }, currentUser) {
    commit('setCurrentUser', currentUser);
  },
  destroyCurrentUser({ commit }) {
    commit('destroyCurrentUser');
  },
  resetUserState({ commit }) {
    commit('resetUserState');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
