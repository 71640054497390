/* eslint consistent-return: "off" */
import store from '@/store';
import axiosAPI from '@/modules/axios';
import configAuthAxios from '@/modules/axios/config';

const baseURL = process.env.VUE_APP_ORIGIN || window.location.origin;

async function guardAuth(to, from, next) {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const { teamName } = to.params;

    if (teamName) {
      configAuthAxios(teamName);

      const { currentUser } = await store.getters;

      if (!currentUser) {
        const account = await axiosAPI.get('/me');

        await store.dispatch('setCurrentUser', account.data);
      }

      return next();
    }

    window.location.href = `${baseURL}/accounts/byteark`;
  } else {
    return next();
  }
}

export default guardAuth;
