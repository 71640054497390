<template>
  <div class="sidebar-container" v-touch:swipe.left="onToggleMenu">
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    onToggleMenu: {
      type: Function,
      default: () => {},
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  watch: {
    customClass(value) {
      if (value) {
        document.body.className = 'modal-opened';
      } else {
        document.body.className = '';
      }
    },
  },
  methods: {
    toggleMenu() {
      this.state.isToggleMenu = !this.state.isToggleMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/sidebar.scss';
</style>
