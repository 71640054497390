import qs from 'qs';
import axios from '@/modules/axios';

function loadGraphData(filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axios.get(`/concurrent-graph${query}`);
}

function loadRealtimeCCU(filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: false });
  return axios.get(`/getconcurrent${query}`);
}

function loadTopList(filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axios.get(`/top-program${query}`);
}

function getDownloadCSVLink(filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axios.get(`/export/top-program${query}`);
}

function loadStations() {
  return axios.get('/stations');
}

export {
  loadRealtimeCCU,
  loadGraphData,
  loadTopList,
  loadStations,
  getDownloadCSVLink,
};
