<template>
  <nav class="navbar navbar-expand-lg navbar-byteark">
    <div class="container">
      <button
        type="button"
        class="navbar-toggler"
        aria-label="Toggle navigation"
        @click.stop="onToggleMenu">
        <i class="fas fa-bars"></i>
      </button>
      <slot
        name="navbar-brand"
        v-if="$slots['navbar-brand']" />
      <slot
        name="navbar-nav-search"
        v-if="$slots['navbar-nav-search']" />
      <slot
        name="navbar-nav"
        v-if="$slots['navbar-nav']" />
      <ul
        class="navbar-nav navbar-nav-user"
        :class="{'ml-auto': !$slots['navbar-nav']}"
        @click.stop="onToggleShowUserMenu"
        v-if="currentUser">
        <li class="nav-item">
          <div class="username">
            {{ cutString(userName) }}
          </div>
          <Logo v-if="currentUser" :user="currentUser"/>
          <div
            v-show="isUserMenuShowing"
            v-click-outside="onClickOutsideUserMenu"
            class="sub-menu">
            <div class="nav-item">
              <div class="user-item" v-if="userTeam">
                <label>Team</label>
                <div class="user-item-value">
                  {{userTeam}}
                </div>
              </div>
              <div class="user-item">
                <label>Email</label>
                <div class="user-item-value">
                  {{userEmail}}
                </div>
              </div>
            </div>
            <slot name="menu-user" v-if="$slots['menu-user']" />
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapGetters } from 'vuex';
import Logo from '@/components/Logo.vue';

export default {
  props: {
    onToggleMenu: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Logo,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      isUserMenuShowing: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'userEmail',
      'userName',
      'userTeam',
    ]),
  },
  methods: {
    onToggleShowUserMenu() {
      this.isUserMenuShowing = !this.isUserMenuShowing;
    },
    onClickOutsideUserMenu() {
      this.isUserMenuShowing = false;
    },
    cutString(string) {
      if (string.length > 12) {
        return `${string.substring(0, 12)} ...`;
      }
      return string;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
@import "~@/assets/scss/themes/components/navbar.scss";
</style>
