<template>
  <div class="page summary-container">
    <!-- <LayoutContainer> -->
      <div class="page-content">
        <EmptyMessage>
          <span slot="icon"><img src="@/assets/image/explore.png"/></span>
          <h1 slot="title" class="title">บริการนี้ยังไม่เปิดให้ใช้งานทั่วไป</h1>
          <p>หากต้องการใช้งานกรุณาติดต่อ <a href="mailto:support@byteark.com">support@byteark.com</a></p>
        </EmptyMessage>
      </div>
    <!-- </LayoutContainer> -->
  </div>
</template>

<script>
// import LayoutContainer from '@/layout/LayoutContainer.vue';
import EmptyMessage from '@/components/EmptyMessage.vue';

export default {
  components: {
    // LayoutContainer,
    EmptyMessage,
  },
  computed: {
    teamName() {
      return this.$route.params.teamName;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/page.scss';

img {
  filter: grayscale(100%);
}

</style>
