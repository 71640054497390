<template>
  <div class="sidebar-app-container">
    <SidebarContainer
      :onToggleMenu="onSwitchToggleMenu"
      :class="sidebarCustomClass()">
      <Sidebar
        class="sidebar"
        :onClose="onCloseToggleMenu"
        :isMobileMenuShowing="stateSidebar.isMobileMenuShowing">
        <router-link
          class="sidebar-brand" slot="sidebar-brand"
          :to="generateHomepageLink()" >
          <img
            src="@/assets/image/logo-explore.png"
            class="logo mr-1"
            width="28" />
          <span>Explore</span>
        </router-link>
        <router-link
          v-for="(menu, index) in sidebarMenu"
          :key="index"
          :to="menu.link"
          class="sidebar-item">
          {{menu.title}}
        </router-link>
        <div
          class="sidebar-item sidebar-item-user"
          slot="sidebar-user">
          <div class="menu-item">
            <div class="user-logo-container">
              <Logo :user="currentUser" />
              <div class="user-info">
                <span class="user-name">{{userName}}</span>
                <span v-if="userTeam" class="user-team">{{userTeam}}</span>
              </div>
            </div>
            <a class="logout-menu" :href="`${baseURL}/accounts/logout?team=${teamName}`">
              <i class="fas fa-sign-out-alt icon"></i>&nbsp;Logout
            </a>
          </div>
        </div>
      </Sidebar>
    </SidebarContainer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getNavbarItems } from '@/utils/navbarUtils';

import Logo from '@/components/Logo.vue';
import SidebarContainer from '@/components/SidebarContainer.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
  props: {
    onToggleMenu: {
      type: Function,
      default: () => {},
    },
    stateSidebar: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sidebarMenu: [],
    };
  },
  components: {
    Logo,
    SidebarContainer,
    Sidebar,
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'userEmail',
      'userName',
      'userTeam',
    ]),
    projectId() {
      return this.$route.params.projectId;
    },
    baseURL() {
      return process.env.VUE_APP_ORIGIN || window.location.origin;
    },
    teamName() {
      return this.$route.params.teamName;
    },
  },
  watch: {
    customClass(value) {
      if (value) {
        document.body.className = 'modal-opened';
      } else {
        document.body.className = '';
      }
    },
    teamName() {
      if (this.teamName) {
        this.sidebarMenu = getNavbarItems(this.teamName);
      }
    },
  },
  methods: {
    sidebarCustomClass() {
      if (this.stateSidebar.isMobileMenuShowing) {
        return 'menu-showing';
      }
      return '';
    },
    onSwitchToggleMenu() {
      this.onToggleMenu(!this.stateSidebar.isMobileMenuShowing);
    },
    onCloseToggleMenu() {
      this.onToggleMenu(false);
    },
    generateHomepageLink() {
      return `/${this.teamName}/report`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

.user-logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .user-info {
    display: flex;
    flex-flow: column;
    margin-left: 10px;
    .user-name {
      font-size: $h5-font-size;
    }
    .user-team {
      font-size: $font-size-base * 0.7;
    }
  }
}

.logout-menu {
  display: block;
  margin-top: $spacer;
  color: $body-color-on-dark;
}


.sidebar-app-container {
  height: 100%;
}

@media (min-width: 991.98px) {
  .sidebar-app-container {
    display: none;
  }
}
</style>
