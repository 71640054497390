<template>
  <div class="navbar-container">
    <Navbar
      :onToggleMenu="onSwitchToggleMenu"
      :user="currentUser">
      <div
        slot="navbar-brand"
        class="navbar-brand">
        <img
          src="@/assets/image/logo-explore.png"
          class="logo mr-1"
          width="28"
          alt="ByteArk Explore" />
        <span>Explore</span>
      </div>
      <ul
        slot="navbar-nav"
        class="navbar-nav">
        <li
          v-for="(menu, index) in navbarItems"
          :key="index"
          class="nav-item">
          <router-link
            class="nav-link"
            :to="menu.link">
            {{menu.title}}
          </router-link>
        </li>
      </ul>
      <div
        slot="menu-user"
        class="menu-user">
        <a
          class="nav-item"
          :href="`${baseURL}/accounts/logout?team=${teamName}`">
          <i class="fas fa-sign-out-alt"></i>&nbsp;Logout
        </a>
      </div>
    </Navbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getNavbarItems } from '@/utils/navbarUtils';
import { generateLink } from '@/utils/generateLink';
import Navbar from '@/components/Navbar.vue';

export default {
  props: {
    onToggleMenu: {
      type: Function,
      default: () => {},
    },
    stateSidebar: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Navbar,
  },
  data() {
    return {
      selectedVideo: null,
      videos: [],
      navbarItems: [],
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    baseURL() {
      return process.env.VUE_APP_ORIGIN || window.location.origin;
    },
    teamName() {
      return this.$route.params.teamName;
    },
  },
  watch: {
    teamName() {
      if (this.teamName) {
        this.navbarItems = getNavbarItems(this.teamName);
      }
    },
  },
  methods: {
    generateLink,
    onCloseToggleMenu() {
      this.onToggleMenu(false);
    },
    onSwitchToggleMenu() {
      this.onToggleMenu(!this.stateSidebar.isMobileMenuShowing);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

.navbar-nav {
  flex: 1;
}
</style>
