import _ from 'lodash';
import qs from 'qs';

const generateLink = (url, oldQuery, selectedDate) => {
  const queryObj = _.assign(_.cloneDeep(oldQuery), selectedDate);
  const query = qs.stringify(queryObj, { addQueryPrefix: true, skipNulls: true });
  if (url) {
    return `${url}${query}`;
  }
  return query;
};

const generateQuery = (oldQuery, newQuery) => _.assign(_.cloneDeep(oldQuery), newQuery);

export {
  generateLink,
  generateQuery,
};
