<template>
  <div v-if="user" class="logo"
    :style="{ ...customStyle(),
      backgroundImage: generateBackgroundImage(),
      backgroundColor: generateBackgroundColor() }">
    <div v-if="userName && !generateBackgroundImage()" class="name">
      {{userName}}
    </div>
    <slot class="name"></slot>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  colorGeneratorFromString,
} from '@/utils/colorGenerator';

export default {
  props: {
    user: {
      type: Object,
    },
    options: {
      type: Object,
      default: () => ({
        width: '36px',
        height: '36px',
        backgroundColor: null,
        color: null,
      }),
    },
  },
  computed: {
    userName() {
      return _.get(this.user, 'name', 'A');
    },
  },
  methods: {
    colorGeneratorFromString,
    generateBackgroundColor() {
      if (this.options && this.options.backgroundColor) {
        return this.options.backgroundColor;
      }
      return this.colorGeneratorFromString(this.userName);
    },
    generateBackgroundImage() {
      const imageObject = _.get(this.user, 'display_image.sizes.large.url', null);
      if (imageObject) {
        return `url(${imageObject})`;
      }
      return null;
    },
    customStyle() {
      return this.options;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

.logo {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: $gray-700;
  color: $gray-200;

  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-size: $font-size-base * 1.25;

  background-size: cover;
  background-position: center center;

  .name {
    line-height: 0;
  }
}

</style>
