<template>
  <div
    :class="{'dark': dark}"
    class="empty-message-container">
    <slot name="icon" class="icon"></slot>
    <slot name="title" class="title"></slot>
    <slot></slot>
    <slot name="code" class="code" v-if="$slots['code']"></slot>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/empty-message.scss';

</style>
