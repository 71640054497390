import Vue from 'vue';
import VueRouter from 'vue-router';
import RootChrome from '@/layout/RootChrome.vue';
import AppChrome from '@/layout/AppChrome.vue';
import ErrorPage from '@/error/ErrorPage.vue';
import PermissionDeniedPage from '@/error/PermissionDeniedPage.vue';
import { createRoutes as createCompareRoutes } from '@/compare';
import { createRoutes as createPrimeTimeRoutes } from '@/primeTime';
import { createRoutes as createReportRoutes } from '@/report';
import guardAuth from '@/router/guard';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/notfound',
      name: 'ErrorPage_1',
      component: ErrorPage,
    },
    {
      path: '/permissiondenied',
      name: 'PermissionDeniedPage',
      component: PermissionDeniedPage,
    },
    {
      path: '/',
      name: 'Root',
      component: RootChrome,
      meta: { requiresAuth: true },
      children: [
        {
          path: ':teamName',
          component: AppChrome,
          children: [
            {
              path: '/',
              redirect: 'report',
            },
            ...createCompareRoutes(),
            ...createPrimeTimeRoutes(),
            ...createReportRoutes(),
            {
              path: '*',
              name: 'ErrorPage_b',
              component: ErrorPage,
            },
          ],
        },
      ],
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(guardAuth);

export default router;
